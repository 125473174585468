
const URL = "https://vouchermanagement.arunshotello.com/api/"


const BASE_URL = "https://vouchermanagement.arunshotello.com/api/admin/"

export const LOGIN_URL = `${BASE_URL}login`;
export const STAFF_URL = `${BASE_URL}users/`;
export const EXPENSE_URL = `${BASE_URL}expensetypes/`;






const USER_BASE_URL = "https://vouchermanagement.arunshotello.com/api/user/"

export const USER_LOGIN_URL = `${USER_BASE_URL}login`;
export const USER_EXPENSE_URL = `${URL}staff/expensetypes/`;
export const USER_VOUCHER_URL = `${URL}staff/vouchers/`;
export const USER_APPROVED_URL = `${URL}staff/acceptlist`;
export const USER_REJECTED_URL = `${URL}staff/rejectlist`;

export const CASHIER_VOUCHER_URL = `${URL}cashier/vouchers/`;
export const CASHIER_APPROVED_URL = `${URL}cashier/acceptlist`;
export const CASHIER_REJECTED_URL = `${URL}cashier/rejectlist`;
export const CASHIER_STATUS_URL = `${URL}cashier/voucherstaffstatues`;



export const MANAGER_VOUCHER_URL = `${URL}manager/vouchers/`;
